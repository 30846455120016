<template>
  <div>
    <Toolbar
      :handle-delete="del"
      :handle-reset="resetForm"
      :handle-submit="onSendForm"
    />
    <TenantGroupForm
      :errors="violations"
      :values="item"
      ref="updateForm"
      v-if="item"
    />
    <Loading :visible="isLoading || deleteLoading"/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {mapFields} from 'vuex-map-fields';
import TenantGroupForm from '../../components/tenantGroup/Form.vue';
import Loading from '../../components/Loading';
import Toolbar from '../../components/Toolbar';
import UpdateMixin from '../../mixins/UpdateMixin';

const servicePrefix = 'TenantGroup';

export default {
  name: 'TenantGroupUpdate',
  servicePrefix,
  mixins: [UpdateMixin],
  components: {
    Loading,
    Toolbar,
    TenantGroupForm
  },
  computed: {
    ...mapFields('tenantGroup', {
      deleteLoading: 'isLoading',
      isLoading: 'isLoading',
      error: 'error',
      updated: 'updated',
      violations: 'violations'
    }),
    ...mapGetters('tenantGroup', ['find'])
  },
  methods: {
    ...mapActions('tenantGroup', {
      deleteItem: 'del',
      retrieve: 'load',
      update: 'update',
    })
  }
};
</script>
